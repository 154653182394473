<template>
  <div>
    <b-overlay :show="isSubmitting" rounded="sm">
      <form-wizard
        :title="null"
        :subtitle="null"
        back-button-text="previous"
        next-button-text="next"
        @on-complete="onSubmit"
      >
        <!-- Order Details tab  -->
        <tab-content
          :title="'Sender Details'"
          icon="feather icon-file-text"
          :before-change="validateFirstSteps"
        >
          <ValidationObserver ref="deliveryDetails" v-slot="{ passes }">
            <form @submit.prevent="passes(validateFirstSteps)">
              <SenderDetails ref="childComponentRef" @setSender="handleSetSender" />
            </form>
          </ValidationObserver>
        </tab-content>
        <tab-content :title="'Recipient & Order Details'" icon="feather icon-shopping-cart">
          <AddressDetails
            ref="addressDetails"
            :categories="categories"
            :remarks="remarks"
            @setForm="handleSetForm"
          />
        </tab-content>
        <b-button slot="finish" class="wizard-btn" variant="primary">
          <b-spinner class="mr-50" small />
          Get A Quotation
        </b-button>
      </form-wizard>
      <b-modal v-model="isConfirmationModalVisible" hide-header centered>
        <p>Quotation will take 1-2 days to be provided. Confirm?</p>
        <p v-if="orderForm && orderForm.items">
          {{
            orderForm.items.total_weight >= 1000 && orderForm.items.lorry === '1 tonne'
              ? 'Exceed 1 tonne maximum weight'
              : orderForm.items.total_weight >= 3000 && orderForm.items.lorry === '3 tonne'
              ? 'Exceed 3 tonne maximum weight'
              : orderForm.items.total_weight >= 5000 && orderForm.items.lorry === '5 tonne'
              ? 'Exceed 5 tonne maximum weight'
              : orderForm.items.total_weight >= 10000 && orderForm.items.lorry === '10 tonne'
              ? 'Exceed 10 tonne maximum weight'
              : orderForm.items.total_weight > 17999 && orderForm.items.lorry === '18 tonne'
              ? 'Exceed 18 tonne maximum weight'
              : ''
          }}
        </p>
        <template #modal-footer="{ cancel }">
          <b-button variant="secondary" @click="cancel"> Cancel </b-button>
          <b-button variant="primary" @click="createDelivery()"> Confirm </b-button>
        </template>
      </b-modal>
      <template #overlay>
        <div class="text-center">
          <b-spinner style="width: 3rem; height: 3rem" label="Large Spinner" variant="primary" />
          <h3 class="mt-3">Processing... Please wait.</h3>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { ValidationObserver } from 'vee-validate'
import { FormWizard, TabContent } from 'vue-form-wizard'
import { AddressDetails, SenderDetails } from '@/components/CharteredTruck'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { mapGetters } from 'vuex'

export default {
  components: {
    FormWizard,
    TabContent,
    AddressDetails,
    SenderDetails,
    ValidationObserver,
    mapGetters,
    ToastificationContent,
  },
  data() {
    return {
      isSubmitting: false,
      isConfirmationModalVisible: false,
      orderForm: {},
    }
  },
  computed: {
    ...mapGetters({
      categories: 'singleDelivery/categories',
      remarks: 'singleDelivery/remarks',
    }),
    loadingMessage() {
      if (this.isLoading) {
        return 'Fetching fields data...'
      }

      if (this.isCheckout) {
        return 'Processing Order...'
      }
      return ''
    },
  },
  created() {
    this.getPort()
    this.$store.dispatch('singleDelivery/fetchFieldsValue')
  },
  methods: {
    async getPort() {
      const port_type = { port_type: this.orderForm.deliveryType }
      const response = await this.$http.post('get_port_by_type', port_type)
      if (response.data.status) {
        this.port = response.data.data
      }
    },
    async onSubmit() {
      try {
        const result = await this.$refs.addressDetails.childMethod()
        return new Promise((resolve, reject) => {
          if (result) {
            this.isConfirmationModalVisible = true
            resolve(true)
          } else {
            reject()
          }
        })
      } catch (error) {
        console.error('Error:', error)
      }
    },
    async createDelivery() {
      this.isConfirmationModalVisible = false
      this.isSubmitting = true
      const response = await this.$http.post('chartered_create', this.orderForm)
      if (response.data.status === true) {
        this.$router.push({
          name: 'charteredtruckdeliverysuccess',
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'warning',
          },
        })
        this.isSubmitting = false
      }
      setTimeout(() => {
        this.isSubmitting = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please retry your order',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }, 20000)
    },
    async validateFirstSteps() {
      try {
        const result = await this.$refs.childComponentRef.childMethod()
        return new Promise((resolve, reject) => {
          if (result) {
            resolve(true)
          } else {
            reject()
          }
        })
      } catch (error) {
        console.error('Error:', error)
      }
    },
    handleSetForm(value) {
      this.orderForm.items = value
    },
    handleSetSender(value) {
      this.orderForm.sender = value
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
